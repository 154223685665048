import * as React from "react";

import Puzzle from "../../Puzzle";

import sekrety from "../../images/sekrety.jpg";
import map from '../../images/m1.png'
import audio from '../../../static/a1.mp3'

const Page = () => {
  return (
    <Puzzle
      title="Zagadka 1"
      author="Autorka: Karina Marusińska"
      imageSrc={sekrety}
      imageAlt="potłuczone naczynie"
      mapSrc={map}
      mapAlt="Punkt zaznaczony na mapie"
      audio={audio}
      language="pl"
      languageUrl={"/riddles/ml8NkbfFdd"}
    >
      <div>
        <p>Praca  nawiązuje do dziecięcych rytuałów – „zabawy w sekrety” zwanej też „widoczkami”. Polega ona na zakopywaniu w ziemi starannie zakomponowanych skarbów (kwiatów, kamieni, koralików, liścików itp.), a następnie przykryciu ich kawałkiem szkła. Tajemnicę o ich istnieniu ujawnia się osobom szczególnym i bliskim jako wyraz zaufania.</p>
        <p>W tradycyjnych „Sekretach” szkło zabezpiecza skarby, które stają się widoczne po odsłonięciu warstw ziemi (szkło chroni skarb i jednocześnie go ujawnia). Szkło jest tu granicą pomiędzy skarbem a światem zewnętrznym, tym, co prywatne i publiczne.</p>
        <p>W moich "Sekretach” fragment potłuczonego szkła traktuje o przemocy - nosi ślady obrażeń skórnych, które są z nim zintegrowane. Jednocześnie sam w sobie jest elementem opresyjnym, m. in. ze względu na ostre krawędzie. Mógłby być więc użyty jako narzędzie ataku lub samoobrony.</p>
        <p>„Sekrety” na które spoglądamy dotyczą zjawiska, które należy do społecznego tabu. Ofiary przemocy domowej zazwyczaj ze wstydu bądź lęku ukrywają dowody jej istnienia. Paradoksalnie jednocześnie towarzyszy temu nadzieja, że ktoś je odkryje i zareaguje, przerywając cierpienie osoby pokrzywdzonej.  To praca o bardzo małych rozmiarach i bardzo dużym ciężarze znaczeniowym.</p>
        <p>Obecnie istnieje zagrożenie wypowiedzeniem przez Polskę Konwencji Rady Europy (Konwencji stambulskiej) o zapobieganiu i zwalczaniu przemocy wobec kobiet i przemocy domowej. Więcej informacji na ten temat pod poniższym linkiem: https://cpk.org.pl/konwencja-stambulska/</p>
      </div>
    </Puzzle>
  );
};

export default Page;
